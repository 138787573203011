import { Link } from 'react-router-dom';
import PosterNaturstunde from '../../../assets/pdf/Poster_Naturstunde.pdf';


const Naturstunde = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
          title: 'Kindergarten Sonnenstrahl',
          href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-2 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-2">
                <h1 className="font-title text-3xl text-dark">Naturstunde</h1>
                <p className="my-8 font-text">
                    Das Projekt "Naturstunde" richtet sich an öffentliche Bildungseinrichtungen -
                    insbesondere an Vorschulschüler:innen im Kindergarten und Grundschulschüler*innen
                    an Grundschulen.
                </p>
                <p className="my-8 font-text">
                    An 5 Terminen im Jahr 2021 gehen wir mit einer festen Gruppe Ihrer Einrichtung raus,
                    erleben die Natur zu den unterschiedlichen Jahreszeiten, lernen heimische Pflanzen
                    und Tiere kennen und verbringen erholsame Stunden im Wald/Park.
                </p>
                <h2 className="mt-4 font-title text-2xl text-dark">
                    Laufzeit
                </h2>
                <p className="my-8 font-text">
                    15. April 2021 – 15. November 2021
                </p>
                <h2 className="mt-4 font-title text-2xl text-dark">
                    Kontakt
                </h2>
                <p className="my-8 font-text">
                    Melanie Heyde<br />
                    m.heyde[at]amoeba-umweltbildung.de<br />
                    0176 8212 5912
                </p>
                <h2 className="mt-4 font-title text-2xl text-dark">
                    Finanzierung
                </h2>
                <p className="my-8 font-text">
                    Diese Maßnahme wird mitfinanziert mit Steuermitteln auf Grundlage des von den
                    Abgeordneten des Sächsischen Landtags beschlossenen Haushaltes.
                </p>
                <p className="my-8 font-text">
                    <a
                        href={PosterNaturstunde}
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-lg hover:text-green"
                    >
                        &rsaquo;&rsaquo; Poster zum Download
                    </a>
                </p>
            </article>
        </section>
    );
};

export default Naturstunde;
