import { Link } from 'react-router-dom';

const Verein = () => {
  return (
    <section className="w-11/12 sm:w-5/6 xl:w-2/3 ls:w-1/2 mx-auto">
      <div className="w-full mt-8 text-center md:text-left">
        <h1 className="font-title text-3xl text-dark">Amöba - Verein für Umweltbildung e.V.</h1>
        <p className="my-8 font-text">
          Im August 2009 schloss sich eine kleine Gruppe in der Umweltbildung engagierter Leute
          zum gemeinnützigen Verein Amöba - Verein für Umweltbildung e.V. zusammen. Seitdem sind
          wir stetig gewachsen, haben inzwischen 16 Mitglieder und sind seit dem 01.04.2011
          eigenständige Einsatzstelle des FÖJ.<br />
          Wir arbeiten in
          <Link className="antialiased font-bold text-dark hover:text-green" to="/aktuelles"> unseren Projekten </Link>
          mit starken Kooperationspartnern wie zum Beispiel der Sächsischen Landesstiftung Natur
          und Umwelt (LaNU), dem UFZ oder der Stiftung Wilderness International zusammen und bieten
          regelmäßig Umweltbildungsveranstaltungen für Kinder im Kindergarten- & Grundschulalter an.
        </p>
        <h2 className="font-title text-2xl text-dark">Warum der Name Amöba?</h2>
        <p className="mt-8 font-text italic">
          "Alles Geniale ist einfach, sagte die Amöbe schon immer." (Michail Genin)
        </p>
        <p className="my-8  font-text">
          Getreu diesem Motto arbeiten wir bei Amöba mit einfachen, zum Großteil alltäglichen
          Mitteln und bieten nachvollziehbare Aktionen und Veranstaltungen im Umweltbildungsbereich
          an.
        </p>
      </div>
      <div className="my-4">
        <h2 className="font-title text-2xl text-dark">Wer sind die Leute bei Amöba?</h2>
        <p className="my-8  font-text">
          Wir sind Naturwissenschaftler, die die Natur nicht nur zur Wissenschaft machen, sondern
          Veranstaltungen zum Anfassen und Verstehen für Mann, Frau und Kind anbieten. Wir wollen
          nachhaltig für ökologischen Themen begeistern. Dazu bieten wir ganzjährig Umweltbildungsaktionen
          für drinnen und draußen an, die spielerisch erlebbar, informativ und kreativ sind.
        </p>
      </div>
    </section >
  );
};

export default Verein;
