import { Link } from 'react-router-dom';

const Sommerferiencamp = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
          title: 'Kindergarten Sonnenstrahl',
          href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-2 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-2">
                <h1 className="font-title text-3xl text-dark">Sommerferiencamp am Wissenschaftspark Leipzig</h1>
                <p className="my-8 font-text">
                  Im Sommerferiencamp bieten wir Tagesbetreuung für Kinder der Beschäftigten der Zentren und Institute im Wissenschaftspark Leipzig, bisher insbesondere unterstützt durch das Helmholtz-Zentrum für Umweltforschung (UFZ), sowie durch das Leibniz-Institut für Troposphärenforschung (TROPOS), das Deutsche Biomasseforschungszentrum (DBFZ) und das Leibniz-Institut für Oberflächenmodifizierung (IOM). Das Angebot richtet sich an Kinder der Altersgruppe 6 bis 12 Jahre.
                </p>
                <h2 className="mt-4 font-title text-2xl text-dark">
                    Termine 2024
                </h2>
                <p className="my-8 font-text">
                  Woche 1: 22.07. – 26.07.2024<br />
                  Woche 2: 29.07. – 02.08.2024
                </p>
                <h2 className="mt-4 font-title text-2xl text-dark">
                    Organisatorisches
                </h2>
                <p className="my-8 font-text">
                  Die Betreuungszeit erstreckt sich täglich von 08:00 bis 17:00 Uhr. Pro Woche können ca. 20 Kinder teilnehmen. Es besteht in der Regel ein Betreuungsschlüssel von ca. 1:5. Das Team besteht aus 2 pädagogischen Leitungspersonen und 3 weiteren Betreuungskräften.
                </p>
                <h2 className="mt-4 font-title text-2xl text-dark">
                    Themen
                </h2>
                <p className="my-8 font-text">
                  Die Campwoche 2024 hat das Thema „Kreisläufe in der Natur entdecken“ und beinhaltet diese Bausteine:<br /><br />
                    Ausflüge zu umweltrelevanten Themen<br />
                    Gemeinschaft und Gruppe<br />
                    Umweltbildung und Naturerfahrung<br />
                    Sport und Bewegung<br />
                    Kreativität und Handwerk
                </p>
                <h2 className="mt-4 font-title text-2xl text-dark">
                    Kontakt
                </h2>
                <p className="my-8 font-text">
                  Nikola Allinger<br />
                  n.allinger[at]amoeba-umweltbildung.de
                </p>
            </article>
        </section>
    );
};

export default Sommerferiencamp;
