import { Link } from 'react-router-dom';


const Muehlenkinder = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
          title: 'Kindergarten Sonnenstrahl',
          href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-2 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-2">
                <h1 className="font-title text-3xl text-dark">Wilde Mühlenkinder</h1>
                <p className="my-8 font-text">
                    Im Jahr 2015 startete das Projekt, bei dem es darum geht, ehemaligen Kindern
                    des Naturkindergartens Wassermühle die Möglichkeit zu bieten, weiterhin
                    naturpädagogische Angebote wahrzunehmen. Seit 2016 sind die Wilden Mühlenkinder
                    ein Kooperationsprojekt zwischen der Deutschen Wildtier Stiftung, FRÖBEL Bildung
                    und Erziehung gGmbH und Amöba. Es finden ca. 10 Termine im Jahreslauf statt.
                    Zu jedem Treffen wird ein neues Natur- und Wildnisthema passend zur Jahreszeit
                    behandelt und vertieft. Ein weiterer wichtiger und wiederkehrender Bestandteil
                    der Treffen sind verschiedene naturpädagogische Spiele und das Entfachen eines
                    Feuers.
                </p>
                <p className="my-8 font-text">
                    Die "Wilden Mühlenkinder" wurden im April 2019 als offizielles Projekt der
                    UN-Dekade "Biologische Vielfalt" ausgezeichnet:
                </p>
                <p className="my-8 font-text">
                    <a
                        href="https://www.froebel-gruppe.de/aktuelles/news-single/artikel/wilde-muehlenkinder-als-offizielles-projekt-der-un-dekade-biologische-vielfalt-ausgezeichnet/"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-lg hover:text-green"
                    >
                        &rsaquo;&rsaquo; Pressemitteilung
                    </a>
                </p>
                <p className="my-8 font-text">
                    <a
                        href="https://wassermuehle.froebel.info/wilde-muehlenkinder/"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-lg hover:text-green"
                    >
                        &rsaquo;&rsaquo; Informationen zum Projekt
                    </a>
                </p>
            </article>
        </section>
    );
};

export default Muehlenkinder;
