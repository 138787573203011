import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MobNavList from '../atoms/MobNavList';
import NavList from '../atoms/NavList';
import logo from '../../assets/img/Logo.png';
import headerfoto from '../../assets/img/Headerfoto.png';

export default function Header() {
  const [changeMobNav, setChangeMobNav] = useState(false);
  const [classChange, setClassChange] = useState(false);

  const handleMobNav = () => {
    setChangeMobNav(!changeMobNav);
    setClassChange(!classChange);
  };

  return (
    <header>
      <section className="w-full p-4 sm:py-4 lg:p-0 flex flex-col justify-between items-center">
        <div className="w-full flex flex-row justify-between items-center">
          <Link className="w-2/5 sm:w-1/3 lg:w-1/4 ls:w-1/5 sm:ml-6 lg:ml-8 sm:mb-2 lg:mb-0 cursor-pointer" to="/aktuelles">
            <img src={logo} alt="Logo" className="w-full" />
          </Link>
          <div className="mr-8 lg:mr-0 lg:ml-4 ls:ml-16 hidden md:block">
            <p
              className="font-banner text-base lg:text-xl xl:text-3xl text-center text-dark"
              style={{ fontVariant: 'small-caps', letterSpacing: '0.3rem' }}
            >
              "Alles Geniale ist <br />
              einfach, <br />
              sagte die Amöbe <br />
              schon immer."
            </p>
            <p className="font-banner text-xs xl:text-sm lg:mt-4 text-center text-light">(Michael Genin)</p>
          </div>
          <div>
            <div onClick={handleMobNav} className="lg:hidden sm:pr-6 cursor-pointer">
              <div className={classChange ? 'changeBar1 bar1' : 'bar1'}></div>
              <div className={classChange ? 'changeBar2 bar2' : 'bar2'}></div>
              <div className={classChange ? 'changeBar3 bar3' : 'bar3'}></div>
            </div>
            {changeMobNav && <MobNavList />}
          </div>
          <figure className="w-1/3 ls:w-1/4 hidden lg:block">
            <img src={headerfoto} alt="Amöba Veranstaltung mit Kindern auf einer Wiese" className="w-full" />
          </figure>
        </div>
        <figure className="w-full lg:hidden">
          <img src={headerfoto} alt="Amöba Veranstaltung mit Kindern auf einer Wiese" className="w-full" />
        </figure>
        <div className="w-full bg-darkgreen hidden lg:block">
          <NavList />
        </div>
      </section>
    </header>
  );
}
