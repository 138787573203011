const Datenschutz = () => {
    return (
        <section className="w-11/12 sm:w-5/6 xl:w-2/3 ls:w-1/2 mx-auto">
            <div className="w-full my-8 text-center md:text-left">
                <h1 className="font-title text-3xl text-dark">Datenschutz&shy;erklärung</h1>
                <h2 className="my-8 font-title text-2xl text-dark">
                    1. Datenschutz auf einen Blick
                </h2>
                <h3 className="font-title text-xl text-dark">Allgemeine Hinweise</h3>
                <p className="my-4 font-text">
                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert,
                    wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert
                    werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
                    aufgeführten Datenschutzerklärung.
                </p>
                <h3 className="font-title text-xl text-dark">Datenerfassung auf unserer Website</h3>
                <h4 className="my-8 font-bold text-lg text-dark">Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
                <p className="my-4 font-text">
                    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
                    dem Impressum dieser Website entnehmen.
                </p>
                <h4 className="my-8 font-bold text-lg text-dark">Wie erfassen wir Ihre Daten?</h4>
                <p className="my-4 font-text">
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten
                    handeln, die Sie in ein Kontaktformular eingeben.<br />
                    Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
                    technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser
                    Daten erfolgt automatisch, sobald Sie unsere Website betreten.
                </p>
                <h4 className="my-8 font-bold text-lg text-dark">Wofür nutzen wir Ihre Daten?</h4>
                <p className="my-4 font-text">
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten
                    können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                </p>
                <h4 className="my-8 font-bold text-lg text-dark">Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
                <p className="my-4 font-text">
                    Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten
                    personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung
                    dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit
                    unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der
                    zuständigen Aufsichtsbehörde zu.
                </p>
            </div>
            <div className="w-full my-8 text-center md:text-left">
                <h2 className="my-8 font-title text-2xl text-dark">2. Allgemeine Hinweise und Pflichtinformationen</h2>
                <h3 className="font-title text-xl text-dark">Datenschutz</h3>
                <p className="my-4 font-text">
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen
                    Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br />
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
                    mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche
                    Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.<br />
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
                    aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                </p>
                <h3 className="font-title text-xl text-dark">Hinweis zur verantwortlichen Stelle</h3>
                <p className="my-4 font-text">
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br /><br />
                    Amöba - Verein für Umweltbildung e.V.<br /><br />
                    Vereinssitz:<br />
                    An den Höfen 12<br />
                    04425 Taucha<br /><br />
                    Postanschrift:<br />
                    Schönbachstr. 50a<br />
                    04299 Leipzig<br /><br />
                    Telefon: 0341 99 38 38 70<br />
                    E-Mail: info@engelstein.de<br /><br />
                    Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
                    die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                </p>
                <h3 className="font-title text-xl text-dark">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                <p className="my-4 font-text">
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits
                    erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
                    der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </p>
                <h3 className="font-title text-xl text-dark">Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
                <p className="my-4 font-text">
                    Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
                    zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes,
                    in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können
                    folgendem Link entnommen werden:<br /><br />
                    <a
                        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                    </a>.
                </p>
                <h3 className="font-title text-xl text-dark">Recht auf Datenübertragbarkeit</h3>
                <p className="my-4 font-text">
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert
                    verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
                    Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
                    soweit es technisch machbar ist.
                </p>
                <h3 className="font-title text-xl text-dark">Auskunft, Sperrung, Löschung</h3>
                <p className="my-4 font-text">
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
                    über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung
                    und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum
                    Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                </p>
                <h3 className="font-title text-xl text-dark">Widerspruch gegen Werbe-Mails</h3>
                <p className="my-4 font-text">
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich
                    angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten
                    sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
                    Spam-E-Mails, vor.
                </p>
            </div>
            <div className="w-full my-8 text-center md:text-left">
                <h2 className="my-8 font-title text-2xl text-dark">3. Datenerfassung auf unserer Website</h2>
                <h3 className="font-title text-xl text-dark">Server-Log-Dateien</h3>
                <p className="my-4 font-text">
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die
                    Ihr Browser automatisch an uns übermittelt. Dies sind:
                </p>
                <ul>
                    <li>Browsertyp und Browserversion</li>
                    <li>verwendetes Betriebssystem</li>
                    <li>Referrer URL</li>
                    <li>Hostname des zugreifenden Rechners</li>
                    <li>Uhrzeit der Serveranfrage</li>
                    <li>IP-Adresse</li>
                </ul>
                <p className="my-4 font-text">
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Die Dateien werden in der Regel nach 7 Tagen gelöscht.<br />
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung
                    eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                </p>

                <p className="mt-12 font-bold text-dark text-sm">
                    Quelle: <a href="http://www.e-recht24.de" target="_blank" rel="noreferrer" className="text-dark hover:text-green">eRecht24</a>
                </p>
            </div>
        </section>
    );
};

export default Datenschutz;
