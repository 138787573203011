import { Link } from 'react-router-dom';
import HHSLogo from '../../../assets/img/projekte/hhs_logo_neu_jpg.jpg';
import Blaetter from '../../../assets/img/projekte/NaturBewegt_Blaetter.jpg';
import Stockbrot from '../../../assets/img/projekte/NaturBewegt_Stockbrot.jpg';


const MobileUmweltbildung = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
            title: 'Kindergarten Sonnenstrahl',
            href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-4 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-4">
                <h1 className="font-title text-3xl text-dark">
                  Natur bewegt!
                </h1>
                <p className="my-4 font-text text-sm">
                  gefördert durch die Heidehofstiftung GmbH
                </p>
                <div className="mt-8 grid grid-cols-1 xl:grid-cols-2 gap-4">
                    <figure className="w-1/3 md:w-1/2 xl:w-2/3 mb-4">
                        <img src={HHSLogo} alt="Logo der Heidehof Stiftung" />
                    </figure>
                </div>
                <p className="my-8 font-text">
                  In 2024 unternehmen wir mit 4 Grundschulklassen und 4 Kita-Gruppen je fünf Ausflüge
                  in die naturnahe Umgebung ihrer Einrichtungen. Mittels spielerischer und kreativer
                  Methoden werden Gruppenprozesse angestoßen, Sozialkompetenzen gestärkt und ein
                  positiver Zugang zur Natur erschlossen. Durch die wiederholten Ausflüge kann das
                  Erlebte nachhaltiger wirken und längere Prozesse können begleitet werden. Während
                  der Projekttage binden wir die Kinder in Entscheidungs- und Feedbackprozesse ein
                  und fördern mit diesem partizipativen Ansatz das Selbstwirksamkeitserleben der
                  Kinder sowie die Reflexion des Erlebten. Ein wichtiges Augenmerk legen wir in diesem
                  Projekt weiterhin darauf, so weit wie möglich Chancengleichheit unabhängig von Herkunft
                  und familiärem Hintergrund zu ermöglichen. Mindestens die Hälfte der Projekttage
                  findet in Schulen oder Kitas aus Vierteln mit sozialer Benachteiligung statt. In
                  Gruppen, in denen eher sozial-psychologische Faktoren oder Sprachhürden eine Rolle
                  spielen, verbessern wir die Teilhabe gezielt durch eine Sprachmittlung bzw. einen
                  höheren Betreuungsschlüssel.
                </p>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                    <figure className="w-full mb-4">
                        <img src={Blaetter} alt="Kinder sammeln Herbstblätter" />
                    </figure>
                    <figure>
                        <img src={Stockbrot} alt="Kinder sitzen mit Stockbrot um eine Feuerschale" />
                    </figure>
                </div>
            </article>
        </section>
    );
};

export default MobileUmweltbildung;
