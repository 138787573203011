import dewist from '../../assets/img/partner/DeWiSt.jpg';
import kimafrau from '../../assets/img/partner/KiMaFrau.png';
import kiumbus from '../../assets/img/partner/KiUmweltBus.png';
import lanu from '../../assets/img/partner/LaNu.png';
import nater from '../../assets/img/partner/NaturErleben.png';
import oekis from '../../assets/img/partner/Oekokiste.png';
import oekola from '../../assets/img/partner/Oekola7.png';
import wilint from '../../assets/img/partner/WildInt.png';

const Partner = () => {
  const partner = [
    { img: lanu, name: 'Sächsische Landesstiftung Natur und Umwelt (LaNU)', link: 'https://www.lanu.de/' },
    { img: oekola, name: 'Ökologisches Landwirtschaftsschulheim Dreiskau-Muckern', link: 'http://oekola7.de/' },
    { img: nater, name: 'Naturerleben Leipzig', link: 'http://www.naturerleben-leipzig.de/' },
    { img: wilint, name: 'Stiftung Wilderness International', link: 'http://www.wilderness-international.org/' },
    { img: kiumbus, name: 'KinderUmweltBus', link: 'https://www.ufz.de/index.php?de=41860' },
    { img: kimafrau, name: 'KindermannFrau Fotografie', link: 'https://www.facebook.com/Kindermannfrau' },
    { img: oekis, name: 'Ökokiste Leipzig', link: 'http://www.oekokiste-leipzig.de/' },
    { img: dewist, name: 'Deutsche Wildtier Stiftung', link: 'https://www.deutschewildtierstiftung.de/' }
  ];

  return (
    <section className="w-11/12 sm:w-5/6 xl:w-2/3 ls:w-1/2 mx-auto">
      <div className="w-full mt-8 text-center md:text-left">
        <div className="w-full">
          <h2 className="my-8 font-title text-dark text-3xl">Amöba unterstützen:</h2>
          <p className="my-8 font-text">
            Wir sind Teil des Oncken Partnerprogramms. Bestellen Sie Ihre Bücher bei Oncken über <br />
            <a
              href="http://www.portal-oncken.de/index.php?partnerprogramm=MzkwXzE5MDE3N181MjE%3D"
              target="_blank"
              rel="noreferrer"
              class="font-bold hover:text-green"
            >
              &rsaquo;&rsaquo; diesen Link <br />
            </a>
          </p>
          <p className="mt-4 font-text">So geht ein Teil des Verkaufspreises direkt an Amoeba.</p>
        </div>
        <h2 className="mt-16 font-title text-3xl text-dark">Unsere Kooperationspartner</h2>
        <div className="my-16 sm:my-20 grid grid-cols-1 gap-2">
          {Array.from(partner).map((inst, i) => (
            <div
              key={i}
              className="md:my-4 lg:my-8 flex flex-col md:flex-row justify-center items-center md:items-end md:grid md:grid-cols-4 md:gap-6 lg:gap-12"
            >
              <figure class="w-1/2 sm:w-1/3 md:w-auto col-span-1">
                <img src={inst.img} alt={inst.name + 'ist Kooperationspartner des Vereins'} />
              </figure>
              <p className="mt-6 mb-10 sm:mb-12 md:my-0 md:col-span-3 font-text">
                <a href={inst.link} target="_blank" rel="noreferrer" className="font-title text-xl">
                  {inst.name}
                </a>
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partner;
