const Impressum = () => {
    return (
        <section className="w-11/12 sm:w-5/6 xl:w-2/3 ls:w-1/2 mx-auto">
            <div className="w-full my-8 text-center md:text-left">
                <h1 className="font-title text-3xl text-dark">Impressum</h1>
                <p className="my-6 font-text text-sm">Angaben gem. § 5 TMG)</p>
                <h2 className="font-title text-2xl text-dark">
                    Amöba - Verein für Umweltbildung e.V.
                </h2>
                <p className="my-8 font-text">
                    Schönbachstr. 50a<br />
                    04229 Leipzig
                </p>
                <h3 className="font-bold text-lg text-dark">Vorstand:</h3>
                <p className="my-8 font-text">
                    Melanie Heyde, Heike Bullmann, Daniela Schall, Claudia Irmscher
                </p>
                <h3 className="font-bold text-lg text-dark">Vereinsregister-Nummer:</h3>
                <p className="my-8 font-text">
                    30979 (Amtsgericht Leipzig)
                </p>
                <h3 className="font-bold text-lg text-dark">Website:</h3>
                <p className="my-8 font-text">
                    gestaltet & erstellt von Lena Lehmann
                </p>
            </div>
            <div className="w-full my-8 text-center md:text-left">
                <h2 className="font-title text-2xl text-dark">
                    Haftungsausschluss (Disclaimer)
                </h2>
                <h3 className="my-8 font-bold text-lg text-dark">Haftung für Inhalte</h3>
                <p className="my-4 font-text">
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen
                    Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                    übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf
                    eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                    Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
                    erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </p>
                <h3 className="my-8 font-bold text-lg text-dark">Haftung für Links</h3>
                <p className="my-4 font-text">
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
                    können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
                    stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                    Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                    Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                    Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Links umgehend entfernen.
                </p>
                <h3 className="my-8 font-bold text-lg text-dark">Urheberrecht</h3>
                <p className="my-4 font-text">
                    Die durch den Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
                    des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und
                    Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                    dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
                    Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                    werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Inhalte umgehend entfernen.
                </p>
                <p className="mt-12 font-bold text-dark text-sm">
                    Quelle: <a href="http://www.e-recht24.de" target="_blank" rel="noreferrer" className="text-dark hover:text-green">eRecht24</a>
                </p>
            </div>
        </section>
    );
};

export default Impressum;
