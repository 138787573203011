import Accordion from '../atoms/Accordion.jsx';

const Themen = () => {
  return (
    <section>
      <div className="w-11/12 sm:w-5/6 lg:w-3/4 xl:w-2/3 ls:w-1/2 mx-auto mt-8 text-center md:text-left">
        <h1 className="font-title text-3xl text-dark">Themen</h1>
      </div>
      <article className="w-full sm:w-5/6 lg:w-3/4 xl:w-2/3 ls:w-1/2 mx-auto my-12">
        <Accordion />
      </article>
    </section>
  );
};

export default Themen;
