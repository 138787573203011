import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function NavList() {
  const [subNav, setSubNav] = useState(false);

  const showSubNav = () => {
    setSubNav(!subNav);
  };

  return (
    <ul
      className="
        w-5/6
        xl:w-2/3
        ls:w-1/2
        mx-auto
        py-4
        relative
        flex
        flex-row
        justify-between
        items-end
        z-10
      "
    >
      <li>
        <Link className="antialiased font-title text-2xl text-white hover:underline" to="/aktuelles">
          Aktuelles
        </Link>
      </li>
      <li onClick={showSubNav}>
        <p className="font-title text-2xl text-white hover:underline cursor-pointer">Angebote &rsaquo;&rsaquo;</p>
        {subNav && (
          <ul
            className="
              py-2
              px-6
              absolute
              bg-white
              top-16
              flex
              flex-col
              justify-between
            "
          >
            <li className="sm:py-2">
              <Link className="antialiased font-title text-2xl text-darkgreen hover:underline" to="/angebote/themen">
                Themen
              </Link>
            </li>
            <li className="sm:py-2">
              <Link className="antialiased font-title text-2xl text-darkgreen hover:underline" to="/angebote/projekte">
                Projekte
              </Link>
            </li>
            <li className="sm:py-2">
              <Link className="antialiased font-title text-2xl text-darkgreen hover:underline" to="/angebote/veranstaltungen">
                Veranstaltungen
              </Link>
            </li>
          </ul>
        )}
      </li>

      <li>
        <Link className="antialiased font-title text-2xl text-white hover:underline" to="/verein">
          Verein
        </Link>
      </li>

      <li>
        <Link className="antialiased font-title text-2xl text-white hover:underline" to="/partner">
          Partner
        </Link>
      </li>
    </ul>
  );
}
