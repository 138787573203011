import { Link } from 'react-router-dom';

const Veranstaltungen = () => {
  return (
    <section className="w-11/12 sm:w-5/6 xl:w-2/3 ls:w-1/2 mx-auto">
      <div className="w-full mt-8 mb-12 xl:mb-20 text-center md:text-left">
        <h1 className="font-title text-3xl text-dark">Veranstaltungen</h1>
        <p className="my-8 font-text">
          Wir führen regelmäßig Veranstaltungen mit verschiedenen Kooperationspartnern durch.
        </p>
        <h2 className="font-title text-2xl text-dark">Aktuelle Projekte:</h2>
        <div className="my-4">
          <ul>
            <li className="my-1">
              {/*<Link className="antialiased font-bold text-dark hover:text-green" to="/aktuelles">
                &rsaquo;&rsaquo; Erdforscherprogramm am Ökologischen Landwirtschaftsschulheim Dreiskau-Muckern
  </Link>*/}
              Erdforscherprogramm am Ökologischen Landwirtschaftsschulheim Dreiskau-Muckern
            </li>
            <li className="my-1">
              {/*<Link className="antialiased font-bold text-dark hover:text-green" to="/aktuelles">
                &rsaquo;&rsaquo; Aktionsstand beim Sommerfest am Helmholtz-Zentrum für Umweltforschung
</Link>*/}
              Aktionsstand beim Sommerfest am Helmholtz-Zentrum für Umweltforschung
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Veranstaltungen;
