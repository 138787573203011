import { Link } from 'react-router-dom';

const Naturausfluege = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
            title: 'Kindergarten Sonnenstrahl',
            href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-2 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-2">
                <h1 className="font-title text-3xl text-dark">Naturausflüge mit Vorschulkindern der SEB-Kitas</h1>
                <p className="my-8 font-text">
                  Seit dem Frühjahr 2022 gehen Naturpädagoginnen des Amöba e.V. mit Vorschulkindern aus KiTas des SEB (Städtischer Eigenbetrieb Behindertenhilfe) in Leipziger Parks, Brachen und Stadtwäldchen auf Entdeckungstour. Erlebnispädagogische Spiele gehören ebenso zu diesen Naturausflügen dazu wie das Erforschen der Tier- und Pflanzenwelt. Auch die Fantasie kommt nicht zu kurz, wenn Waldwichtel gebastelt oder Geschichten erzählt werden. Bisher nahmen die KiTa Schatzkiste (Leipzig Reudnitz), die Kita Dschungelbande (Leipzig Anger-Crottendorf) und die KiTa Kleine Handwerksmeister (Leipzig Reudnitz) am Projekt teil. Die im Juli 2023 neu eröffnete SEB-Komplexkita Naturwerkstatt ist passend zu ihrer Schwerpunktsetzung auf Naturthemen seit Herbst 2023 ebenfalls dabei und hat mit ihren Gruppen nach Eis- und Schneeausflügen inzwischen auch schon den Frühling intensiv in der Natur erlebt.
                </p>
            </article>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-2">
                <h1 className="font-title text-3xl text-dark">Naturausflüge mit den Bewohner*innen der Wohnstätte Losinskiweg</h1>
                <p className="my-8 font-text">
                  Eine Kooperation mit dem SEB und der Wohnstätte Losinskiweg besteht seit 2020. Das Projekt „Naturausflüge“ hat auf die Bewohner*innen eine mobilisierende und vitalisierende Wirkung. Die Bewohner*innen verlassen für einen halben Tag die Einrichtung und entdecken in Begleitung und Anleitung unserer Naturpädagogin und eine*r*s Mitarbeiter*in*s der Einrichtung stadtnahe Naturräume und erfahren ihre individuellen Reize. Weitere Effekte sind, dass Bewohner*innen sich in gemischten Gruppen kennen lernen, dass Selbstwert und Selbstvertrauen gefördert wird und dass Inklusion der Bewohner*innen in Stadt-Natur-Räume eine Selbstverständlichkeit erfährt und öffentlich wirksam ist.
                </p>
            </article>
        </section>
    );
};

export default Naturausfluege;
