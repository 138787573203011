import { Link } from 'react-router-dom';


const Projekte = () => {
  const subSubNav = [
    {
      title: 'Wilde Mühlenkinder',
      href: '/angebote/projekte/muehlenkinder'
    },
    {
      title: 'Mobile Umweltbildung',
      href: '/angebote/projekte/mobile-umweltbildung'
    },
    {
      title: 'Naturcamp (ESF plus)',
      href: '/angebote/projekte/naturcamp'
    },
    {
      title: 'Sommerferiencamp',
      href: '/angebote/projekte/sommerferiencamp'
    },
    {
      title: 'Naturausflüge mit SEB-Einrichtungen',
      href: '/angebote/projekte/naturausfluege'
    },
    {
      title: 'FreiDay',
      href: '/angebote/projekte/freiday'
    },
    {
      title: 'Kindergarten Sonnenstrahl',
      href: '/angebote/projekte/kindergarten-sonnenstrahl'
    },
    {
        title: 'Natur bewegt',
        href: '/angebote/projekte/natur-bewegt'
    }
  ]

  return (
    <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-4 lg:gap-6">
      <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
        <ul>
          {subSubNav.map((navItem, i) => (
            <li className="my-2" key={i}>
              <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                {navItem.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-4">
        <h1 className="font-title text-3xl text-dark">Unsere Projekte</h1>
        <p className="my-8 font-text text-dark">
          Wir arbeiten in unseren Projekten eng mit anderen Institutionen zusammen.
          So haben wir Kooperationen mit der Sächsischen Landesstiftung Natur und Umwelt (LaNU),
          dem Helmholtz-Zentrum für Umweltforschung (UFZ) oder mit Schulen oder KiTas der Stadt
          Leipzig.
        </p>
        <h2 className="font-title text-2xl text-dark">Aktuelle Projekte:</h2>
        <div className="my-4">
          <ul>
            {subSubNav.map((navItem, i) => (
              <li className="my-1" key={i}>
                <Link className="antialiased font-bold text-dark hover:text-green" to={navItem.href}>
                  {navItem.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <h2 className="mt-12 font-title text-2xl text-dark">Abgeschlossene Projekte:</h2>
        <div className="my-8">
          <div>
            <h3 className="font-bold text-lg text-darkgreen">„StadtGrünschnäbel“ für KiTas und Grundschulen</h3>
            <p className="my-4 font-text text-dark">
              Das Projekt "StadtGrünschnäbel", im Jahr 2023 umgesetzt, richtet sich an Kitas und Grundschulen in sozial benachteiligten Stadtvierteln Leipzigs. Die teilnehmenden Gruppen aus Leipziger Bildungseinrichtungen werden zu je 5 Terminen von Naturpädagoginnen des Amöba e.V. in die Natur begleitet, mit folgenden Themenschwerpunkten:
            </p>
            <ul className="mx-2 font-text text-lg text-dark">
              <li>
                1. Entdecken und Erforschen: Vielfalt in der Natur
              </li>
              <li>
                2. Finden, Verwenden und Bewahren: Schätze aus der Natur
              </li>
              <li>
                3. Ersinnen und Erschaffen: Werkeln mit Fantasie und Bushcraft
              </li>
              <li>
                4. Laufen, Hüpfen und Verknüpfen: Spiele & Anschauliches zu ökologischen Zusammenhängen
              </li>
              <li>
                5. Verstehen und Handeln: vom Alltag in die Zukunft
              </li>
            </ul>
            <p className="my-4 font-text text-dark">
              Dabei werden jeweils 2 Termine in Kooperation mit Gemeinschaftsgärten, offenen Gärten oder soziokulturellen Stadtteiltreffs auf deren naturnahem Gelände durchgeführt. Die übrigen 3 Veranstaltungen finden in Stadtparks oder im Stadtwald statt.
            </p>
            <p className="my-4 font-text text-dark">
              Die Fördermittel wurden von der Sächsischen Landesstiftung Natur und Umwelt (LaNU) bereitgestellt.
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg text-darkgreen">Naturstunde</h3>
            <p className="my-4 font-text text-dark">
              Das Projekt "Naturstunde" richtete sich an öffentliche Bildungseinrichtungen -
              insbesondere an Vorschulschüler*innen im Kindergarten und Grundschulschüler*innen
              an Grundschulen.
            </p>
            <p className="my-4 font-text text-dark">
              An 5 Terminen im Jahr 2021 gingen wir mit einer festen Gruppe der jeweiligen Einrichtung raus,
              erlebten die Natur zu den unterschiedlichen Jahreszeiten, lernten heimische Pflanzen
              und Tiere kennen und verbrachten erholsame Stunden im Wald / Park.
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg text-darkgreen">KinderUmweltBus</h3>
            <p className="my-4 font-text text-dark">
              Mobile Umwelbildungseinsätze für Vor- und Grundschulkinder mit dem KinderUmweltBus der
              Sächsischen Landesstiftung für Natur und Umwelt.
            </p>
            <p className="my-4 font-text text-dark">
              2019 und 2020 boten wir direkt in den Kindergärten, Grundschulen und Horten kostenfreie
              Einsätze mit dem KinderUmweltBus an - finanziert mit Fördermitteln der Sächsischen
              Landesstiftung Natur und Umwelt (LaNU). Damit konnte unser Verein ein inhaltlich auf
              den Bildungs- und Lehrplan abgestimmtes und an die Bedürfnisse der Kindergärten und
              Grundschulen angepasstes Angebot an altersgerechten Umweltbildungseinsätzen für sächsische
              Kinder im Alter von 5 bis 10 Jahren bereitstellen.
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg text-darkgreen">Mobile Umweltbildung für Vor- und Grundschulkinder 2019</h3>
            <p className="my-4 font-text text-dark">
              Im Rahmen des Kooperationsprojektes "Mobile Umweltbildung für Vor- und Grundschulkinder 2019"
              zwischen Amöba e.V. und der Sächsischen Landesstiftung Natur- und Umwelt (LaNU) hat der
              KinderUmweltBus 2019 insgesamt 70 Einsätze an 34 verschiedenen Einrichtungen durchgeführt.
              Insgesamt konnten so 1132 Kinder von 5 bis 10 Jahren von den Einsätzen des KinderUmweltBusses
              profitieren, davon 264 Kinder in Kindertageseinrichtungen (Vorschüler) und 868 Grundschulkinder.<br />
              Am 03.05.2019 und 30.08.2019 wurden zwei ganztägige Fortbildungen zum Thema Insekten mit
              dem Titel „Insekten! Zum Sterben verurteilt?“ im Raum Leipzig durchgeführt. Bei der ersten
              Fortbildung nahmen 17 Teilnehmer teil, bei der zweiten 16 Teilnehmer.<br />
              Die Fördermittel wurden von der Sächsischen Landesstiftung Natur und Umwelt (LaNU) bereit gestellt.
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg text-darkgreen">Mobile Umweltbildungseinsätze für Vor- und Grundschulkinder 2018</h3>
            <p className="my-4 font-text text-dark">
              Im Rahmen des Kooperationsprojektes "Mobile Umweltbildung für Vor- und Grundschulkinder 2018"
              zwischen Amöba e.V. und der Sächsischen Landesstiftung Natur- und Umwelt (LaNU) hat der
              KinderUmweltBus 100 mobile Umweltbildungseinsätze in 39 Kindergärten und Grundschulen in
              ganz Sachsen gefahren. Somit konnten 1775 Kinder zwischen 5-10 Jahren von den auf den
              sächsischen Bildungsplan und Lehrplan abgestimmten Umweltbildungsangeboten profitieren.<br />
              Die Fördermittel wurden von der Sächsischen Landesstiftung Natur und Umwelt (LaNU) bereit gestellt.
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg text-darkgreen">GTA "Naturforscher - denken mit!"</h3>
            <p className="my-4 font-text text-dark">
              In den Schuljahren 2011 - 2015 hat Heike Franke, Dozentin von Amöba e.V., an der freien
              Grundschule in Püchau die GTA "Naturforscher - denken mit!" durchgeführt. Die GTA fand
              jeden "Schul-Montag" statt und entführte die "jungen Forscher" in die unterschiedlichsten
              Lebensräume. Mit großer Begeisterung untersuchten sie u.a.das Krabbeln in der Wiese,
              nahmen Gewässer unter die Lupe, erforschten das Leben unter ihren Füßen, bauten Nistkästen,
              nahmen bei der Vogelzählung des NABU und beschäftigten sich viel mit einem nachhaltigen Umgang.
            </p>
            <p className="my-4 font-text text-dark">
              Durch die kontinuierliche Umweltbildungsarbeit mit den Kindern (viele Kinder waren ihre
              gesamte Grundschulzeit dabei) sind wir überzeugt davon, dass wir im Sinne von BNE (Bildung
              für nachhaltige Entwicklung) einen entscheidenden Beitrag leisten konnten!
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg text-darkgreen">Warum? - Darum! Umweltethik für Kinder</h3>
          </div>
          <div>
            <h3 className="font-bold text-lg text-darkgreen">Herbstakademie (gefördert mit ESF-Mitteln)</h3>
          </div>
          <div>
            <h3 className="font-bold text-lg text-darkgreen">Wildnislauf "Take a Walk for the Wild" in Leipzig</h3>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Projekte;
