import { Link } from 'react-router-dom';
import logo from '../../assets/img/Logo.png';
import hygienekonzept from '../../assets/pdf/Hygienekonzept.pdf';
import satzung from '../../assets/pdf/Satzung_Amoeba_2021-11-03.pdf'

export default function Footer() {
  return (
    <footer className="w-full bg-light">
      <div className="w-full p-8 grid grid-cols-1 lg:grid-cols-12 gap-4 lg:gap-8 items-end">
        <div className="lg:col-span-3">
          <Link className="cursor-pointer" to="/aktuelles">
            <img src={logo} alt="Logo" className="w-2/3 500:w-1/2 md:w-1/3 mx-auto lg:w-full" />
          </Link>
        </div>
        <div className="col-span-1"></div>

        <section className="mt-6 lg:mt-0 text-darkgreen text-center lg:text-left lg:col-span-3">
          <p className="my-2 text-darkgreen">Schönbachstr. 50a</p>
          <p className="text-darkgreen">04299 Leipzig</p>
        </section>

        <section className="mt-6 lg:mt-0 text-darkgreen text-center lg:text-left lg:col-span-3">
          <h2 className="my-4 lg:my-0 font-title text-xl">Kontakt</h2>
          <p className="my-2 lg:my-0 text-darkgreen">info [at] amoeba-umweltbildung.de</p>
        </section>

        <section
          className="
            w-full
            sm:w-3/4
            md:w-2/3
            mx-auto
            mb-6
            mt-8
            lg:my-0
            pt-10
            lg:pt-0
            border-t-2
            border-darkgreen
            lg:border-none
            lg:col-span-2
            grid
            grid-cols-2
            lg:grid-cols-1
            gap-4"
        >
          <div>
            <a href={satzung} target="_blank" rel="noreferrer" className="w-2/5 lg:w-full font-title text-darkgreen text-2xl hover:underline">
              Satzung            </a>
          </div>
          <div>
            <a href={hygienekonzept} target="_blank" rel="noreferrer" className="w-2/5 lg:w-full font-title text-darkgreen text-2xl hover:underline">
              Hygienekonzept
            </a>
          </div>
          <div>
            <Link className="w-2/5 lg:w-full font-title text-darkgreen text-2xl hover:underline" to="/impressum">
              Impressum
            </Link>
          </div>
          <div>
            <Link className="w-2/5 lg:w-full font-title text-darkgreen text-2xl hover:underline" to="/datenschutz">
              Datenschutz
            </Link>
          </div>
        </section>
      </div>
    </footer>
  );
}
