import { Link } from 'react-router-dom';
import PVSonnenstrahl1 from '../../../assets/img/projekte/PreisverleihungSonnenstrahl_1.jpg';
import PVSonnenstrahl2 from '../../../assets/img/projekte/PreisverleihungSonnenstrahl_2.jpg';

const Sonnenstrahl = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
          title: 'Kindergarten Sonnenstrahl',
          href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-4 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-4">
                <h1 className="font-title text-3xl text-dark">Umweltbildungsarbeit im Kindergarten Sonnenstrahl</h1>
                <p className="my-8 font-text">
                    Pädagogischer Leitgedanke des Projekts ist das Motto „Bestaunen – Begreifen – Beschützen“.
                    Bei der naturpädagogischen Arbeit im Kindergarten soll anhand direkter Naturbegegnung
                    ein Gefühl für die Natur vermittelt werden: die Begeisterung darüber, dass man mit
                    wachen Sinnen viele kleine und große Wunder entdecken kann. Dabei geht es zuerst um
                    das Bestaunen. Ist die Neugier geweckt, tauchen viele Fragen auf, deren Antworten
                    Wissen wachsen lässt – man fängt an zu Begreifen. Hat man eine Landschaft, Pflanzen
                    und Tiere kennengelernt, Wunder dabei entdeckt, so entsteht der Wunsch, diese zu
                    bewahren und verantwortungsvoll mit Natur und Umwelt umzugehen. Der Schritt in Richtung
                    Beschützen ist getan.
                </p>
                <p className="my-8 font-text">
                    Das Projekt wird durchgeführt in Kooperation mit FRÖBEL Bildung und Erziehung gGmbH
                    und finanziert vom Helmholtz-Zentrum für Umweltforschung GmbH - UFZ.
                </p>
                <p className="my-8 font-text">
                    Der Kindergarten Sonnenstrahl ist mit der Projektidee "Ein naturnaher Garten bei
                    uns im Kindergarten Sonnenstrahl - Teil 1 Planung" Preisträger der Sächsischen
                    Mitmachfonds in der Kategorie MINT.
                </p>
                <p className="my-8 font-text">
                    <a
                        href="https://www.mitmachfonds-sachsen.de/2019/06/24/ministerpraesident-kretschmer-zeichnet-preistraeger-der-saechsischen-mitmach-fonds-aus/"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-lg hover:text-green"
                    >
                        &rsaquo;&rsaquo; Lesen SIe mehr dazu
                    </a>
                </p>
                <p className="my-8 font-text text-sm">
                    [Diese Maßnahme wird mitfinanziert durch Steuermittel auf der Grundlage des von
                    den Abgeordneten des Sächsischen Landtags beschlossenen Haushalts.]
                </p>
                <p className="my-8 font-text">
                    Außerdem gewann der Kindergarten beim 6. Sächsischen Kinder-Garten-Wettbewerb und
                    gehört nun zu den 30 schönsten Kinder-Gärten Sachsens.
                </p>
                <p className="my-8 font-text">
                    <a
                        href="https://www.medienservice.sachsen.de/medien/news/226528"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-lg hover:text-green"
                    >
                        &rsaquo;&rsaquo; Lesen Sie mehr dazu
                    </a>
                </p>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                    <figure className="w-full mb-4">
                        <img src={PVSonnenstrahl1} alt="Preisträger vor Sächsischem Landtag" />
                    </figure>
                    <figure>
                        <img src={PVSonnenstrahl2} alt="Preisträger Gruppenfoto" />
                    </figure>
                </div>
                <h2 className="mt-4 font-title text-2xl text-dark">
                    Kontakt
                </h2>
                <p className="my-8 font-text">
                    Daniela Schall<br />
                    Email: d.schall[at]amoeba-umweltbildung.de
                </p>
            </article>
        </section>
    );
};

export default Sonnenstrahl;
