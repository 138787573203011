import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/hooks/ScrollToTop';
import './App.css';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import Aktuelles from './components/pages/Aktuelles';
import Themen from './components/pages/Themen';
import Projekte from './components/pages/Projekte';
import Veranstaltungen from './components/pages/Veranstaltungen';
import Verein from './components/pages/Verein';
import Partner from './components/pages/Partner';
import Muehlenkinder from './components/pages/projekte/Muehlenkinder';
import MobileUmweltbildung from './components/pages/projekte/MobileUmweltbildung';
import Naturcamp from './components/pages/projekte/Naturcamp';
import NaturcampsGalerie from './components/pages/projekte/NaturcampsGalerie';
import Naturstunde from './components/pages/projekte/Naturstunde';
import NaturBewegt from './components/pages/projekte/NaturBewegt';
import Sommerferiencamp from './components/pages/projekte/Sommerferiencamp';
import Naturausfluege from './components/pages/projekte/Naturausfluege';
import FreiDay from './components/pages/projekte/FreiDay';
import Sonnenstrahl from './components/pages/projekte/Sonnenstrahl';
import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';

export default function App() {
  const paths = [
    { name: '/aktuelles', component: <Aktuelles /> },
    { name: '/angebote/themen', component: <Themen /> },
    { name: '/angebote/projekte', component: <Projekte /> },
    { name: '/angebote/projekte/muehlenkinder', component: <Muehlenkinder /> },
    { name: '/angebote/projekte/mobile-umweltbildung', component: <MobileUmweltbildung /> },
    { name: '/angebote/projekte/natur-bewegt', component: <NaturBewegt /> },
    { name: '/angebote/projekte/naturcamp', component: <Naturcamp /> },
    { name: '/angebote/projekte/naturcamps-galerie', component: <NaturcampsGalerie /> },
    { name: '/angebote/projekte/naturstunde', component: <Naturstunde /> },
    { name: '/angebote/projekte/sommerferiencamp', component: <Sommerferiencamp /> },
    { name: '/angebote/projekte/naturausfluege', component: <Naturausfluege /> },
    { name: '/angebote/projekte/freiday', component: <FreiDay /> },
    { name: '/angebote/projekte/kindergarten-sonnenstrahl', component: <Sonnenstrahl /> },
    { name: '/angebote/veranstaltungen', component: <Veranstaltungen /> },
    { name: '/verein', component: <Verein /> },
    { name: '/partner', component: <Partner /> },
    { name: '/impressum', component: <Impressum /> },
    { name: '/datenschutz', component: <Datenschutz /> },
    { name: '/', component: <Aktuelles /> }
  ];

  return (
    <BrowserRouter>
      <div>
        <Header />
        <main className="w-full p-4">
          {<Routes>
            {paths.map((val, i) => (
              <Route path={val.name} element={val.component} />
            ))}
          </Routes>}
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
