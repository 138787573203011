import { Link } from 'react-router-dom';

const FreiDay = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
            title: 'Kindergarten Sonnenstrahl',
            href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-2 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-2">
                <h1 className="font-title text-3xl text-dark">Umweltbildung für FreiDay-Schulen in Leipzig und Umland</h1>
                <p className="my-8 font-text">
                  Das Projekt "Umweltbildung für Schulen mit FreiDay oder Projektorientierung" wird im Jahr 2024 in Leipzig und Umland (Landkreise Leipzig und Nordsachsen) umgesetzt. Erfahrene Natur- und Umweltpädagoginnen des Amöba e.V. unterstützen die teilnehmenden Schulen darin, im Rahmen von FreiDay-Projekttagen oder Projektwochen für den Natur- und Umweltschutz aktiv zu werden. Zu je 5 Terminen werden die Gruppen dabei begleitet, relevante Natur- und Umweltthemen in ihrem Umfeld zu erkennen und dann ins Handeln zu kommen. Möglich sind u.a. folgende Themen:
                </p>
                <ul class="mx-6 font-text text-lg text-dark list-disc">
                  <li>
                    Natur entdecken und erforschen
                  </li>
                  <li>
                    Stadtklima und -natur
                  </li>
                  <li>
                    Nachhaltiger Schulgarten bzw. Schulhof
                  </li>
                </ul>
                <p className="my-8 font-text">
                  Die teilnehmenden Schulen können auch eigene Themenwünsche einbringen. Wichtig ist uns, dass die Schüler*innen kein vorgefertigtes Projekt durchlaufen, sondern sich eigenverantwortlich mit Fragestellungen und Lösungen auseinander setzen können.
                </p>
                <p className="my-8 font-text">
                  Die Fördermittel werden von der Sächsischen Landesstiftung Natur und Umwelt (LaNU) bereitgestellt.
                </p>
            </article>
        </section>
    );
};

export default FreiDay;
