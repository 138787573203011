import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SimpleAccordion() {
    const accordionItems = [
        {
            title: 'Boden',
            content: 'Ob draußen mit Schippe und Eimer, oder drinnen im Labor: Wir untersuchen als Erdforscher mit den Kindern den Boden und erkunden diesen Lebensraum und seine tierischen Bewohner mit allen Sinnen. (ab 3 Jahren)'
        },
        {
            title: 'Wald & Wiese',
            content: 'Am besten vor Ort machen wir Wald & Wiesen mit ihrer Artenvielfalt für die Kinder erlebbar. Wir lernen die heimischen Köstlichkeiten von Baum, Strauch und Wiese kennen oder gehen auf eine Reise in die tropischen Wälder mit ihren Schätzen.'
        },
        {
            title: 'Nasser Zauber',
            content: 'Aktionen zum, mit und im Wasser: Wassertiere keschern und bestimmen, Schwimmen & Sinken, Löslichkeit & Wasserfarben, Wasserkraft & allerlei mehr Wasserspielerei gehören zu unseren Wasseraktionen.'
        },
        {
            title: 'Energie',
            content: 'Experimentell sollen die Kinder kennenlernen, was Thermik, Wind, Wasser, Sonne und Pflanzen mit dem Strom aus unserer Steckdose zu tun haben. (ab 5 Jahren)'
        },
        {
            title: 'Nachhaltigkeit & Gerechtigkeit',
            content: 'Alltag ist oft ein schneller Strom und wir schwimmen mit. Wir wollen uns die Zeit nehmen, eine Pause einzulegen und nach links & rechts zu schauen, uns Fragen zu stellen zu Gerechtigkeit & Nachhaltigkeit. (ab 8 Jahren)'
        },
        {
            title: 'Tierisch gut',
            content: 'In verschiedenen Aktionen, z. B. bei der Spurensuche (auch im Schnee) oder in unserer Fledermausnacht, lernen die Kinder verschiedene Tierarten genauer kennen.'
        },
        {
            title: 'Natur & Kunst',
            content: 'Wir streifen mit den Kindern durch die Natur, sammeln, matschen, bauen & gestalten. Über die Dauer dieser Ausstellung entscheidet dann die Natur allein. (ab 2 Jahren)'
        },
        {
            title: 'Unter freiem Himmel',
            content: 'Wir gehen mit den Kindern auf eine Reise zurück zur Natur, bauen wärmende Schutzhütten und genießen das Leben am Feuer. (ab 7 Jahren)'
        },
        {
            title: 'Weitere Themen',
            content: 'Neben diesen haben wir noch einige weitere Themen im Angebot, z. B. Artenvielfalt, Jahreszeiten oder Ernährung. Außerdem sind wir sehr offen für Ihre Wünsche und Vorschläge. Sprechen Sie uns einfach an: info@amoeba-umweltbildung.de'
        }
    ]

    return (
        <div>
            {accordionItems.map((thema, i) => (
                <Accordion key={i}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h2 className="font-title text-xl lg:text-2xl text-darkgreen">
                            {thema.title}
                        </h2>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p className="font-text">
                            {thema.content}
                        </p>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
