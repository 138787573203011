import { Link } from 'react-router-dom';
import MMFVerleihung1 from '../../../assets/img/projekte/PreisverleihungAmoebaLastenrad_1.jpg';
import MMFVerleihung2 from '../../../assets/img/projekte/PreisverleihungAmoebaLastenrad_2.jpg';


const MobileUmweltbildung = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
            title: 'Kindergarten Sonnenstrahl',
            href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-4 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-4">
                <h1 className="font-title text-3xl text-dark">
                    Mobile Umwelbildungseinsätze für Vor- und Grundschulkinder
                </h1>
                <p className="my-8 font-text">
                    Wir bieten mobile Umweltbildung für Vor- und Grundschulkinder mit unserem Lastenrad
                    im nahe gelegen Park, Wald oder auf Ihrem Einrichtungsgelände an. Die Projektidee
                    wurde in der Kategorie Mobilität der Sächsischen Mitmachfonds prämiert.
                </p>
                <p className="my-8 font-text">
                    <a
                        href="https://www.mitmachfonds-sachsen.de/2019/06/24/ministerpraesident-kretschmer-zeichnet-preistraeger-der-saechsischen-mitmach-fonds-aus/"
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold text-lg hover:text-green"
                    >
                        &rsaquo;&rsaquo; Lesen Sie mehr dazu
                    </a>
                </p>
                <p className="my-8 font-text text-sm">
                    [Diese Maßnahme wird mitfinanziert durch Steuermittel auf der Grundlage des von
                    den Abgeordneten des Sächsischen Landtags beschlossenen Haushalts.]
                </p>
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                    <figure className="w-full mb-4">
                        <img src={MMFVerleihung1} alt="Amöba Mitglied vor der Preisträgerwand" />
                    </figure>
                    <figure>
                        <img src={MMFVerleihung2} alt="Amöba Mitglied nimmt Preis entgegen" />
                    </figure>
                </div>
                <div>
                    <h2 className="my-12 font-title text-2xl text-dark">
                        Eckdaten der Einsätze:
                    </h2>
                    <h3 className="font-bold text-lg text-dark">Dauer:</h3>
                    <p className="my-8 font-text">
                        ca. 120 - 180  Minuten
                    </p>
                    <h3 className="font-bold text-lg text-dark">Gruppengröße:</h3>
                    <p className="my-8 font-text">
                        8-14 Kinder bzw. eine Schulklasse mit 2 Betreuer:innen
                    </p>
                    <h3 className="font-bold text-lg text-dark">Themen:</h3>
                    <p className="my-8 font-bold">
                        Vielfalt der Lebensräume entdecken und erleben (insb. Lebensraum Wald, Wiese, Wasser)
                    </p>
                    <h3 className="font-bold text-lg text-dark">Kontakt und Informationen:</h3>
                    <p className="my-8 font-text">
                        Email: vorstand[at]amoeba-umweltbildung.de
                    </p>
                </div>
            </article>
        </section>
    );
};

export default MobileUmweltbildung;
