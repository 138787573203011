import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function MobNavList() {
  const [subNav, setSubNav] = useState(false);

  const showSubNav = () => {
    setSubNav(!subNav);
  };

  return (
    <ul
      className="
        w-full
        py-4
        px-8
        absolute
        top-20
        500:top-24
        md:top-36
        right-0
        bg-darkgreen
        bg-opacity-80
        flex
        flex-col
        justify-between
        items-end
      "
    >
      <li className="py-3">
        <Link
          className="antialiased font-title text-2xl sm:text-3xl text-white text-right hover:underline"
          to="/aktuelles"
        >
          Aktuelles
        </Link>
      </li>
      <li className="py-3" onClick={showSubNav}>
        <p className="font-title text-2xl sm:text-3xl text-white text-right hover:underline cursor-pointer">
          &lsaquo;&lsaquo; Angebote
        </p>
        {subNav && (
          <ul
            className="
              py-4
              px-8
              absolute
              top-12
              500:top-24
              right-40
              sm:right-48
              bg-white
              flex
              flex-col
              justify-between
              items-end
            "
          >
            <li className="py-3">
              <Link
                className="antialiased font-title text-2xl sm:text-3xl text-darkgreen text-right hover:underline"
                to="/angebote/themen"
              >
                Themen
              </Link>
            </li>
            <li className="py-3">
              <Link
                className="antialiased font-title text-2xl sm:text-3xl text-darkgreen text-right hover:underline"
                to="/angebote/projekte"
              >
                Projekte
              </Link>
            </li>
            <li className="py-3">
              <Link
                className="antialiased font-title text-2xl sm:text-3xl text-darkgreen text-right hover:underline"
                to="/angebote/veranstaltungen"
              >
                Veranstaltungen
              </Link>
            </li>
          </ul>
        )}
      </li>

      <li className="py-3">
        <Link
          className="antialiased font-title text-2xl sm:text-3xl text-white text-right hover:underline"
          to="/verein"
        >
          Verein
        </Link>
      </li>

      <li className="py-3">
        <Link
          className="antialiased font-title text-2xl sm:text-3xl text-white text-right hover:underline"
          to="/partner"
        >
          Partner
        </Link>
      </li>
    </ul>
  );
}
