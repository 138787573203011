import { Link } from 'react-router-dom';
import urkundenuebergabe2020 from '../../assets/img/news/Urkundenuebergabe.jpg';
import gruppenfoto2020 from '../../assets/img/news/Gruppenfoto_Mitmachfond.jpg';
import zeitungsartikel2020 from '../../assets/img/news/MZ_Artikel.jpg';
import lastenrad2019_1 from '../../assets/img/news/Lastenrad2019_1.jpg';
import lastenrad2019_2 from '../../assets/img/news/Lastenrad2019_2.jpg';

const Aktuelles = () => {
  const imageUrls2020 = [urkundenuebergabe2020, gruppenfoto2020, zeitungsartikel2020];
  const imageUrls2019 = [lastenrad2019_1, lastenrad2019_2];

  return (
    <section className="w-11/12 sm:w-5/6 xl:w-2/3 ls:w-1/2 mx-auto">
      <div className="w-full mt-8 text-center md:text-left">
        <h1 className="font-title text-3xl text-dark">Aktuelle Informationen</h1>
      </div>
      <div>
      <article className="my-8">
          <h2 className="font-title text-2xl text-dark">Wir freuen uns über unser neues Projekt "Natur bewegt!"</h2>
          <p className="my-8 font-text">
            Gefördert von der Heidehofstiftung können wir mit 4 Grundchulklassen und 4 Kitagruppen
            jeweils 5 Projkettage im Grünen durchführen.< br/>
            <Link
              to="/angebote/projekte/natur-bewegt"
              className="font-bold hover:text-green"
            >
              &rsaquo;&rsaquo; mehr dazu lesen <br />
            </Link>
          </p>
        </article>
        <article className="my-8">
          <h2 className="font-title text-2xl text-dark">Preisübergabe beim Sächsischen Mitmachfonds 2020</h2>
          <p className="my-8 font-text">
            Amöba - Verein für Umweltbildung e.V. ist 2020 Preisträger der Sächsischen Mitmachfonds in der Kategorie
            MINT! Wir gewannen 5.000 € für unsere Projektidee "Gemeinsam unterwegs".
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 md:gap-3 xl:gap-4">
            {Array.from(imageUrls2020).map((url, i) => (
              <div key={i}>
                <img src={url} alt="Amöba beim Sächsischen Mitmachfonds 2020" />
              </div>
            ))}
          </div>
        </article>
        <article className="my-16">
          <h2 className="font-title text-2xl text-dark">Projekt 2019 - Lastenrad für mobile Umweltbildungsangebote</h2>
          <p className="my-8 font-text">
            Amöba - Verein für Umweltbildung e.V. war 2019 Preisträger der Sächsischen Mitmachfonds in der Kategorie
            Mobilität! Wir gewannen 5.000 € für unsere Projektidee "Lastenrad mit elektrischer Unterstützung für mobile
            Umweltbildungsangebote".
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 md:gap-3 xl:gap-4">
            {Array.from(imageUrls2019).map((url, i) => (
              <div key={i}>
                <img src={url} alt="Amöba Vorstand mit dem neuen Lastenrad" />
              </div>
            ))}
          </div>
        </article>
      </div>
    </section>
  );
};

export default Aktuelles;
