import { Link } from 'react-router-dom';
import NaturcampArtikel from '../../../assets/pdf/Artikel_EU_Zeit2_2019.pdf';
import UNDekade from '../../../assets/img/projekte/191019_028_UN-Dekade_Gewinnerlogo_Ausgezeichnetes_Projekt-2020_280x280px_RZ01.jpg';
import SommercampAnmeldung from '../../../assets/pdf/Anmeldung_Teilnehmervertrag_Sommer.pdf';
import HerbstcampAnmeldung from '../../../assets/pdf/Anmeldung_Teilnehmervertrag_Herbst.pdf';
import EULogoQuer from '../../../assets/img/projekte/EFRE-ESF_LO_Kombination_EU-Logo_SachsenSignet-klassisch_H_RGB_600dpi.jpg';
import EULogoHoch from '../../../assets/img/projekte/EFRE-ESF_LO_Kombination_EU-Logo_SachsenSignet-klassisch_V_RGB_600dpi.jpg';

const Naturcamp = () => {
    const subSubNav = [
        {
            title: 'Wilde Mühlenkinder',
            href: '/angebote/projekte/muehlenkinder'
        },
        {
            title: 'Mobile Umweltbildung',
            href: '/angebote/projekte/mobile-umweltbildung'
        },
        {
            title: 'Naturcamp (ESF plus)',
            href: '/angebote/projekte/naturcamp'
        },
        {
            title: 'Sommerferiencamp',
            href: '/angebote/projekte/sommerferiencamp'
        },
        {
          title: 'Naturausflüge mit SEB-Einrichtungen',
          href: '/angebote/projekte/naturausfluege'
        },
        {
          title: 'FreiDay',
          href: '/angebote/projekte/freiday'
        },
        {
          title: 'Kindergarten Sonnenstrahl',
          href: '/angebote/projekte/kindergarten-sonnenstrahl'
        },
        {
            title: 'Natur bewegt',
            href: '/angebote/projekte/natur-bewegt'
        }
    ]

    return (
        <section className="w-full ls:px-10 lg:grid lg:grid-cols-3 xl:grid-cols-4 lg:grid-rows-6 lg:gap-6">
            <nav className="w-full -mt-12 lg:mt-8 ls:mt-12 mb-12 p-4 bg-darkgreen relative z-10 lg:col-span-1">
                <ul>
                    {subSubNav.map((navItem, i) => (
                        <li className="my-2" key={i}>
                            <Link className="antialiased font-title text-2xl text-white hover:underline" to={navItem.href}>
                                {navItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <article className="w-11/12 sm:w-5/6 md:w-3/4 mx-auto mt-8 ls:mt-12 text-center md:text-left lg:col-span-2 xl:col-span-3 lg:row-span-6">
                <h1 className="font-title text-3xl text-dark">
                    Naturcamp 2022-2027
                </h1>
                <p className="my-4 font-text text-sm">
                  eine Maßnahme der Richtlinie des Sächsischen Staatsministeriums für Kultus zur Förderung von aus dem Europäischen Sozialfonds Plus 2021- 2027 mitfinanzierten Vorhaben zur Erhöhung der Bildungspotenziale für das lebenslange Lernen
                </p>
                <div className="my-12">
                  <figure className="hidden md:block w-full max-w-xl">
                      <img src={EULogoQuer} alt="Logo Kofinanzierung Europäische Union quer" />
                  </figure>
                  <figure className="md:hidden w-full max-w-lg">
                      <img src={EULogoHoch} alt="Logo Kofinanzierung Europäische Union hoch" />
                  </figure>
                </div>
                <div className="flex flex-col justify-between items-center">
                    <div>
                        <p className="my-4 font-text">
                            Das Projekt Naturcamp wurde als offizielles Projekt der UN-Dekade "Biologische
                            Vielfalt" ausgezeichnet:
                        </p>
                        <p className="my-4 font-text">
                            <a
                                href="https://www.undekade-biologischevielfalt.de/projekte/aktuelle-projekte-beitraege/detail/projekt-details/show/Wettbewerb/2911/"
                                target="_blank"
                                rel="noreferrer"
                                className="font-bold text-lg hover:text-green"
                            >
                                &rsaquo;&rsaquo; Projektseite UN-Dekade Biologische Vielfalt
                            </a>
                        </p>
                        <p className="my-4 font-text">
                            <a
                                href={NaturcampArtikel}
                                target="_blank"
                                rel="noreferrer"
                                className="font-bold text-lg hover:text-green"
                            >
                                &rsaquo;&rsaquo; Artikel EU-Zeit 2 - 2019
                            </a>
                        </p>
                    </div>
                    <div>
                        <figure className="w-full mb-4">
                            <img src={UNDekade} alt="Bild Auszeichnung UN-Dekade Biologische Vielfalt" />
                        </figure>
                    </div>
                </div>
                <div className="my-12">
                    <h2 className="mt-4 font-title text-2xl text-dark">
                        Ziele
                    </h2>
                    <ul className="my-4 px-8 font-text list-disc text-left text-dark">
                        <li className="my-2">Entwicklung von Selbst- und Sozialkompetenz</li>
                        <li className="my-2">Entwicklung von Teamfähigkeit und Verantwortungsbereitschaft in gesellschaftspolitischen, kulturellen oder interkulturellen Zusammenhängen</li>
                        <li className="my-2">individuelle Förderung und Erhöhung der Lernmotivation</li>
                    </ul>
                    <p className="my-8 font-text">
                        Amöba - Verein für Umweltbildung e.V. bietet in den Schulferien seit 2015
                        Naturcamps mit dem Schwerpunkt Lernförderung und Kompetenztraining an. Mittels
                        erlebnis- und naturpädagogischer Übungen und sozialpädagogischer Begleitung
                        werden die Teilnehmer*innen in ihren Kompetenzen und Fähigkeiten geschult und
                        gestärkt. Inhaltlich wird im Projekt zu den Themen Biologische Vielfalt,
                        Achtsamkeit und Kommunikation, sowie Bewegung und Kreativität gearbeitet.
                    </p>
                    {/* <p className="font-text">
                        <Link className="antialiased font-bold text-lg text-dark hover:text-green" to="/angebote/projekte/naturcamps-galerie">
                            &rsaquo;&rsaquo; zur Bildergalerie vergangener Naturcamps
                        </Link>
                    </p> */}
                </div>
                <div className="my-12">
                    <h2 className="mt-8 font-title text-2xl text-dark">
                        Teilnahmevoraussetzungen
                    </h2>
                    <ul className="my-4 px-8 font-text list-disc text-left text-dark">
                        <li className="my-2">
                            Teilnehmer an den geförderten Vorhaben müssen Schüler sein, die ihren Wohnsitz
                            oder ihren gewöhnlichen Aufenthalt im Freistaat Sachsen haben oder eine Schule
                            im Freistaat Sachsen besuchen
                        </li>
                        <li className="my-2">
                            Schülerinnen und Schüler in der Regel ab Klassenstufe 7
                        </li>
                        <li className="my-2">
                            der Schüler oder die Schülerin weist auf dem letzten (Halb-)Jahreszeugnis
                            einen Notenschnitt von 3,0 oder schlechter auf
                        </li>
                        <li className="my-2">
                            es wird durch die Schule eine Teilnahme des Schülers oder der Schülerin empfohlen,
                            weil ein besonderer Unterstützungsbedarf, insbesondere im Hinblick auf eine
                            mögliche Gefährdung der Versetzung, besteht
                        </li>
                        <li className="my-2">
                            der Schüler oder die Schülerin besucht ein Förderzentrum
                        </li>
                    </ul>
                </div>
                <div className="my-12">
                    <h2 className="my-8 font-title text-2xl text-dark">
                        Termine
                    </h2>
                    {/* <p className="font-text">
                        <a
                            href={NaturcampInfos}
                            target="_blank"
                            rel="noreferrer"
                            className="font-bold text-lg hover:text-green"
                        >
                            &rsaquo;&rsaquo; Infos
                        </a>
                    </p> */}
                    <h3 className="mt-12 font-bold text-lg text-dark">Sommercamp 2024: Junicorn</h3>
                    <p className="my-8 font-text">
                        <span className="font-bold">22. - 28. Juni 2024</span> - Ferienhaus Am Kugelzippel | Saalendorf 16 | 02799 Großschönau / Oberlausitz
                    </p>
                    <p className="font-text">
                        <a
                            href={SommercampAnmeldung}
                            target="_blank"
                            rel="noreferrer"
                            className="font-bold text-lg hover:text-green"
                        >
                            &rsaquo;&rsaquo; Anmeldung
                        </a>
                    </p>
                    <h3 className="mt-12 font-bold text-lg text-dark">Herbstcamp 2024:</h3>
                    <p className="my-8 font-text">
                        <span className="font-bold">13. - 19. Oktober 2024</span> - Gästehaus „Zum guten Hirten“ | Obere Dorfstr. 17 | 08626 Wohlbach / Vogtland
                    </p>
                    <p className="font-text">
                        <a
                            href={HerbstcampAnmeldung}
                            target="_blank"
                            rel="noreferrer"
                            className="font-bold text-lg hover:text-green"
                        >
                            &rsaquo;&rsaquo; Anmeldung
                        </a>
                    </p>
                </div>
                <div className="my-12">
                    <h2 className="my-8 font-title text-2xl text-dark">
                        Projektkoordination
                    </h2>
                    <p className="my-8 font-text">
                        Melanie Heyde
                    </p>
                    <p className="font-text">
                        Kontakt: <span className="font-bold">m.heyde[at]amoeba-umweltbildung.de</span>
                    </p>
                </div>
                <div className="my-12">
                    <h2 className="my-8 font-title text-2xl text-dark">
                        Dozenten und sozialpäd. Begleiter
                    </h2>
                    <ul className="my-4 px-8 font-text list-disc text-left text-dark">
                        <li className="my-2">
                            Heyde, Melanie (Dipl. Soziologin, Naturpädagogin, Tourenleiterin, syst. Beraterin);
                            siehe auch <a href="http://naturerleben-leipzig.de/" target="_blank" rel="noreferrer" className="font-bold hover:text-green">
                                NATURERLEBEN - LEIPZIG</a>
                        </li>
                        <li className="my-2">
                            Hohmann, Katharina (Dipl. Sozialpädagogin/-Arbeiterin FH, Prozessbegleiterin)
                        </li>
                        <li className="my-2">
                            Knöfler, Martin (Dipl. Sozialpädagoge, Prozessbegleiter)
                        </li>
                        <li className="my-2">
                            Selling, Sidney (staatl. anerk. Erzieher, Systemischer Berater, Supervisor, Naturpädagoge); siehe auch <a href="https://naturbegleiter.wordpress.com/" target="_blank" rel="noreferrer" className="font-bold hover:text-green">
                                NATURBEGLEITER</a>
                        </li>
                    </ul>
                </div>
                <div className="my-12">
                    <h2 className="my-8 font-title text-2xl text-dark">
                        Aktuell Kooperierende Schulen
                    </h2>
                    <p className="my-8 font-text">
                        Schüler ab Klassenstufe 7 der folgenden Kooperationsschulen nehmen am Projekt teil:
                    </p>
                    <p className="font-text">
                        Ernst-Zinna-Schule, Schule zur Lernförderung der Stadt Leipzig<br />
                        Fritz-Gietzelt-Schule, Schule zur Lernförderung der Stadt Leipzig<br />
                    </p>
                </div>
            </article>
        </section>
    );
};

export default Naturcamp;
