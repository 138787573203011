import { Link } from 'react-router-dom';
import Herbst2016_1 from '../../../assets/img/projekte/galerie/NCHerbst2016_1.png';
import Herbst2016_2 from '../../../assets/img/projekte/galerie/NCHerbst2016_2.png';
import Herbst2016_3 from '../../../assets/img/projekte/galerie/NCHerbst2016_3.png';
import Herbst2016_4 from '../../../assets/img/projekte/galerie/NCHerbst2016_4.png';
import Herbst2016_5 from '../../../assets/img/projekte/galerie/NCHerbst2016_5.png';
import Sommer2016_1 from '../../../assets/img/projekte/galerie/NCSommer2016_1.png';
import Sommer2016_2 from '../../../assets/img/projekte/galerie/NCSommer2016_2.png';
import Sommer2016_3 from '../../../assets/img/projekte/galerie/NCSommer2016_3.png';
import Sommer2016_4 from '../../../assets/img/projekte/galerie/NCSommer2016_4.png';
import Sommer2016_5 from '../../../assets/img/projekte/galerie/NCSommer2016_5.png';
import Winter2017_1 from '../../../assets/img/projekte/galerie/NCWinter2017_1.png';
import Winter2017_2 from '../../../assets/img/projekte/galerie/NCWinter2017_2.png';
import Winter2017_3 from '../../../assets/img/projekte/galerie/NCWinter2017_3.png';
import Winter2017_4 from '../../../assets/img/projekte/galerie/NCWinter2017_4.png';
import Winter2017_5 from '../../../assets/img/projekte/galerie/NCWinter2017_5.png';

const NaturcampsGalerie = () => {
    const gallery = [
        {
            src: Herbst2016_1,
            alt: 'Kind beim Bogenschiessen',
            title: 'Herbstcamp 2016'
        },
        {
            src: Herbst2016_2,
            alt: 'nackter Fuss und Schuh',
            title: 'Herbstcamp 2016'
        },
        {
            src: Herbst2016_3,
            alt: 'Kind beim Basteln',
            title: 'Herbstcamp 2016'
        },
        {
            src: Herbst2016_4,
            alt: 'zwei Kinder beim Paddeln',
            title: 'Herbstcamp 2016'
        },
        {
            src: Herbst2016_5,
            alt: 'Gruppe beim Paddeln',
            title: 'Herbstcamp 2016'
        },
        {
            src: Sommer2016_1,
            alt: 'drei Mädchen basteln am Tisch',
            title: 'Sommercamp 2016'
        },
        {
            src: Sommer2016_2,
            alt: 'Schlauchboot im Fluss',
            title: 'Sommercamp 2016'
        },
        {
            src: Sommer2016_3,
            alt: 'zwei Jungen beim Bogenschiessen',
            title: 'Sommercamp 2016'
        },
        {
            src: Sommer2016_4,
            alt: 'zwei Mädchen spielen Memory',
            title: 'Sommercamp 2016'
        },
        {
            src: Sommer2016_5,
            alt: 'zwei Schlauchboote auf dem Fluss',
            title: 'Sommercamp 2016'
        },
        {
            src: Winter2017_1,
            alt: 'Tierspur im Schnee',
            title: 'Wintercamp 2017'
        },
        {
            src: Winter2017_2,
            alt: 'Kinder von hinten beim Wandern im Schnee',
            title: 'Wintercamp 2017'
        },
        {
            src: Winter2017_3,
            alt: 'zwei Mädchen'
        },
        {
            src: Winter2017_4,
            alt: 'Junge hat Nuss gefunden',
            title: 'Wintercamp 2017'
        },
        {
            src: Winter2017_5,
            alt: 'zwei Kinder in einer Hängematte',
            title: 'Wintercamp 2017'
        }
    ];

    return (
        <section className="w-full ls:px-10">
            <article className="w-11/12 mx-auto my-8 ls:my-12 text-center md:text-left">
                <h1 className="mb-12 font-title text-3xl text-darkgreen">Bilder vergangener Naturcamps</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 items-center">
                    {gallery.map((img, i) => (
                        <figure key={i}>
                            <img src={img.src} alt={img.alt} title={img.title} class="w-full" />
                        </figure>
                    ))}
                </div>
            </article>
            <p class="my-8 text-xs text-right text-dark hover:text-green">
                <Link to="/angebote/projekte/naturcamp">
                    &rsaquo;&rsaquo; zurück zur Projektseite
                </Link>
            </p>
        </section>
    );
};

export default NaturcampsGalerie;
